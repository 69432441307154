import { useAppSelector } from "../../hooks/store"
import { selectMenu } from "../../store/menu/menu.slice"
import "./PreloadCategoryImages.css"

// eslint-disable-next-line no-empty-pattern
export default function PreloadCategoryImages({}: IProps) {
  const { categoriesMetadata } = useAppSelector(selectMenu)
  if (!categoriesMetadata) return null

  return (
    <>
      {categoriesMetadata.map((x) => (
        <img
          key={x.name}
          src={x.imageUrl}
          alt={x.name}
          className="preloaded-image"
        />
      ))}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
