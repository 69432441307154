import Empanadas from "../../components/Empanadas"
import Header from "../../components/Header"
import Location from "../../components/Location"
import Menu from "../../components/Menu"
import Nosotros from "../../components/Nosotros"
import { useAppSelector } from "../../hooks/store"
import { selectMenu } from "../../store/menu/menu.slice"
import "./MainScreen.css"

// eslint-disable-next-line no-empty-pattern
export default function MainScreen({}: IProps) {
  const { menuData, categoriesMetadata } = useAppSelector(selectMenu)
  return (
    <div className="main-screen">
      <Header />

      <Empanadas />

      <Menu data={menuData} categoriesMetadata={categoriesMetadata} />

      <Nosotros />

      <Location />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
