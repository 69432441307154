import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"

import logger from "../../services/logger"
import { IMenuCategoryMetadata, IMenuData } from "../../types/menu"
import Breadcrumbs from "../Breadcrumbs"
import MenuFamily from "../MenuFamily"
import MenuItems from "../MenuItems"
import PreloadCategoryImages from "../PreloadCategoryImages"
import Section from "../Section"
import "./Menu.css"

// eslint-disable-next-line no-empty-pattern
export default function Menu({ data, categoriesMetadata }: IProps) {
  const isFirstLoadRef = useRef(true)
  const [selectedFamilyName, setSelectedFamilyName] = useState<any>("")
  const [selectedSubFamilyName, setSelectedSubFamilyName] = useState<any>("")

  useEffect(() => {
    if (!isFirstLoadRef.current) return

    isFirstLoadRef.current = false

    if (window.location.hash !== "#menu") return

    const menuEl = document.querySelector("#menu")

    if (!menuEl) {
      logger.error("Menu element not fount")
      return
    }

    setTimeout(() => {
      menuEl.scrollIntoView({ behavior: "smooth" })
    }, 2000)
  }, [])

  const crumbClick = (crumb: string, index: number) => {
    if (index === 0) {
      setSelectedFamilyName("")
      setSelectedSubFamilyName("")
    } else if (index === 1) {
      setSelectedFamilyName(crumb)
      setSelectedSubFamilyName("")
    } else {
      setSelectedSubFamilyName(crumb)
    }
  }

  const shouldDisplayFamily = () =>
    data &&
    categoriesMetadata &&
    !selectedSubFamilyName &&
    (!selectedFamilyName || doesSelectedFamilyHasSubFamily())

  const shouldDisplayMenuItems = () =>
    (data &&
      categoriesMetadata &&
      selectedFamilyName &&
      !doesSelectedFamilyHasSubFamily()) ||
    selectedSubFamilyName

  const getSelectedFamily = () =>
    selectedFamilyName &&
    Object.entries(data).find(([name]) => name === selectedFamilyName)![1]

  const getSelectedSubFamilyItems = () =>
    selectedFamilyName &&
    selectedSubFamilyName &&
    Object.entries(getSelectedFamily()).find(
      ([name]) => name === selectedSubFamilyName,
    )![1]

  const getSelectedFamilyItems = () => getSelectedFamily().none

  const doesSelectedFamilyHasSubFamily = () =>
    selectedFamilyName && !getSelectedFamily().none

  const selectFamilyOrSubFamily = (name: string) => {
    document.querySelector("#menu")!.scrollIntoView({
      behavior: "smooth",
    })
    if (selectedFamilyName) {
      setSelectedSubFamilyName(name)
    } else {
      setSelectedFamilyName(name)
    }
  }

  const Back = () => (
    <div
      onClick={back}
      className={`menu-family-back ${selectedFamilyName ? "" : "hidden"}`}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
      Atras
    </div>
  )

  const Crumbs = () => (
    <div className={`crumbs-wrapper ${selectedFamilyName ? "" : "hidden"}`}>
      <Back />
      <Breadcrumbs
        crumbs={["Carta", selectedFamilyName, selectedSubFamilyName]}
        onClick={crumbClick}
      />
    </div>
  )

  const back = () =>
    selectedSubFamilyName
      ? setSelectedSubFamilyName("")
      : setSelectedFamilyName("")

  const Loaders = () => (
    <div className="menu-families">
      {[...Array(6).keys()].map((idx) => (
        <div key={idx} className="menu-family">
          <div className="skeleton-box image"></div>
          <div className="skeleton-box text"></div>
        </div>
      ))}
    </div>
  )

  const shouldDisplayLoaders = () =>
    !shouldDisplayFamily() && !shouldDisplayMenuItems()

  return (
    <Section title="Carta" className="menu" id="menu" direction="right">
      <Crumbs />
      <PreloadCategoryImages />

      {shouldDisplayLoaders() && <Loaders />}

      {shouldDisplayFamily() && (
        <MenuFamily
          data={data}
          categoriesMetadata={categoriesMetadata}
          selectedFamilyName={selectedFamilyName}
          selectedFamily={getSelectedFamily()}
          selectedSubFamilyName={selectedSubFamilyName}
          onSelectFamilyOrSubFamily={selectFamilyOrSubFamily}
        />
      )}
      {shouldDisplayMenuItems() && (
        <MenuItems
          items={getSelectedSubFamilyItems() || getSelectedFamilyItems()}
        />
      )}
    </Section>
  )
}

interface IProps {
  data: IMenuData
  categoriesMetadata: IMenuCategoryMetadata[]
}
