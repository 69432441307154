import logger from "../services/logger"
import { IStore } from "../store"
import { setCategoriesMetadata, setMenuData } from "../store/menu/menu.slice"
import { IMenuCategoryMetadata, IMenuData } from "../types/menu"

const GOOGLE_SPREADSHEET_API_KEY = "AIzaSyBgpR72YM13gzvfXRXcboYxlErAftXkA0Y"
const discoveryDocs = [
  "https://sheets.googleapis.com/$discovery/rest?version=v4",
]
const spreadsheetId = "1SEgY49GCeqazhSwbZlAGRFc7Vn7oW6RKoW9Gt-dO6ok"
const menuSpreadsheetRange = "Menu!A:F"
const categorySpreadsheetRange = "Category!A:B"

export default function initMenuData(store: IStore) {
  const interval = setInterval(async () => {
    // @ts-ignore
    if (window.isGapiLoaded && window.isGsiLoaded) {
      gapi.load("client", () => onGapiClientLoaded(store))

      clearInterval(interval)
    }
  }, 100)
}

/*
 * Helpers
 */
const onGapiClientLoaded = async (store: IStore) => {
  await intializeGapiClient()
  loadMenuData(store)
  loadCategoryMetaData(store)
}

const intializeGapiClient = async () => {
  await gapi.client.init({
    apiKey: GOOGLE_SPREADSHEET_API_KEY,
    discoveryDocs,
  })
  logger.log("Gapi initialized")
}

const loadMenuData = async (store: IStore) => {
  const response = await gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId,
    range: menuSpreadsheetRange,
  })

  const formattedMenuData = formatMenuData(response.result.values.slice(1))
  store.dispatch(setMenuData(formattedMenuData))
}

const loadCategoryMetaData = async (store: IStore) => {
  const response = await gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId,
    range: categorySpreadsheetRange,
  })

  const categoryMetaData = formatCategoryMetadata(
    response.result.values.slice(1),
  )
  store.dispatch(setCategoriesMetadata(categoryMetaData))
}

function formatMenuData(values: string[][]): IMenuData {
  return values.reduce((acc, item) => {
    // eslint-disable-next-line prefer-const,@typescript-eslint/no-unused-vars
    let [family, subFamily, name, price, _, description] = item
    subFamily = subFamily || "none"
    family = family.includes("Empanadas") ? "Empanadas" : family
    acc[family] = acc[family] || {}
    acc[family][subFamily] = acc[family][subFamily] || []

    acc[family][subFamily].push({
      family,
      subFamily,
      name,
      price: Number(price),
      description,
    })
    return acc
  }, {} as any)
}

function formatCategoryMetadata(values: string[][]): IMenuCategoryMetadata[] {
  return values.reduce((acc, item) => {
    const [name, imageUrl] = item
    return acc.concat({ name, imageUrl })
  }, [] as any)
}
