import React from "react"

import logoSrc from "../../images/logo-square.png"
import InViewportAnimation from "../InViewportAnimation"
import "./Section.scss"

export default function Section({
  title,
  children,
  className = "",
  direction,
  ...props
}: IProps) {
  return (
    <div {...props} className={[className, "section"].join(" ")}>
      <h1 className="section-title">
        <div className="b-container">
          <img src={logoSrc} alt="logo" className="section-title-logo" />
          {title}
        </div>
      </h1>
      <InViewportAnimation direction={direction}>
        <div className="b-container">
          <div className="section-body">{children}</div>
        </div>
      </InViewportAnimation>
    </div>
  )
}

interface IProps {
  children?: React.ReactNode
  title: string
  className?: string
  id?: string
  direction?: "left" | "right" | "up"
}
