import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faWhatsapp,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"

import ExternalLink from "../ExternalLink"
import {
  FB_LINK,
  INSTAGRAM_LINK,
  WHATSAPP_LINK,
  WRITE_GOOGLE_REVIEW_LINK,
} from "../../constants/constants"
import logoSrc from "../../images/logo-wide.png"

import "./Header.css"

// eslint-disable-next-line no-empty-pattern
export default function Header({}: IProps) {
  return (
    <div className="b-container fade-in header">
      <a href="/" className="logo">
        <img src={logoSrc} alt="logo" />
      </a>
      <div className="header-links">
        <ExternalLink
          href={WRITE_GOOGLE_REVIEW_LINK}
          style={{ color: "inherit" }}
        >
          ❤️ Hacer una reseña
        </ExternalLink>
        <ExternalLink href={INSTAGRAM_LINK} style={{ color: "purple" }}>
          <FontAwesomeIcon icon={faInstagram} />
        </ExternalLink>
        <ExternalLink href={FB_LINK}>
          <FontAwesomeIcon icon={faFacebook} />
        </ExternalLink>
        <ExternalLink href={WHATSAPP_LINK} style={{ color: "green" }}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </ExternalLink>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
