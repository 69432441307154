import "./MenuItems.css"

// eslint-disable-next-line no-empty-pattern
export default function MenuItems({ items }: IProps) {
  return items.map(({ name, description, price }: any) => (
    <div className="menu-item" key={name}>
      <div className="menu-item-left">
        <div>{name}</div>
        {description && (
          <div className="menu-item-description">{description}</div>
        )}
      </div>
      <div className="menu-item-price">{price.toFixed(2)}</div>
    </div>
  ))
}

interface IProps {
  items: any
}
