import {
  IMenuCategoryMetadata,
  IMenuData,
  IMenuFamilyName,
  IMenuSubFamilyName,
} from "../../types/menu"
import "./MenuFamily.css"

// eslint-disable-next-line no-empty-pattern
export default function MenuFamily({
  data,
  categoriesMetadata,
  selectedFamilyName,
  selectedFamily,
  onSelectFamilyOrSubFamily,
}: IProps) {
  const doesSelectedFamilyHasSubFamily = () => !selectedFamily.none

  const getFamilies = () =>
    Object.entries(data).map(([name]) => {
      return {
        name,
      }
    })

  const getSubFamilies = () =>
    selectedFamilyName &&
    doesSelectedFamilyHasSubFamily() &&
    Object.entries(selectedFamily).map(([name]) => {
      return {
        name,
      }
    })

  const getFamilyOrSubToDisplay = () => getSubFamilies() || getFamilies()

  const getCateogryMedata = (name: string) =>
    categoriesMetadata.find((cm) => cm.name === name)

  return (
    <div className="menu-families">
      {getFamilyOrSubToDisplay().map(({ name }) => (
        <div
          key={name}
          className="menu-family"
          onClick={() => onSelectFamilyOrSubFamily(name)}
        >
          <img
            alt={name}
            src={
              getCateogryMedata(name)?.imageUrl ||
              "https://via.placeholder.com/150"
            }
          />
          <div>{name}</div>
        </div>
      ))}
    </div>
  )
}

interface IProps {
  data: IMenuData
  categoriesMetadata: IMenuCategoryMetadata[]
  selectedFamilyName: IMenuFamilyName
  selectedSubFamilyName: IMenuSubFamilyName
  selectedFamily: any
  onSelectFamilyOrSubFamily: (name: string) => void
}
