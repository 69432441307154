import { createLogger } from "redux-logger"

import { shouldSupressLog } from "../../services/logger"
import { isTest } from "../../utils/env"

const loggerMiddleware = createLogger({
  // predicate: () => !shouldSupressLog(), // uncomment for tests
  predicate: () => !shouldSupressLog() && !isTest(),
})

export default loggerMiddleware
