import "./Nosotros.css"

import img from "../../images/nosotros.jpeg"
import Section from "../Section"

// eslint-disable-next-line no-empty-pattern
export default function Nosotros({}: IProps) {
  return (
    <Section title="Nosotros" direction="left">
      <div className="nosotros">
        <img src={img} alt="nosotros" />
        <p>
          Hemos abierto Blessing con la idea de mimar a nuestros clientes con
          productos de calidad: bollería y pastelería francesa; buen café; una
          copa de vino y unas deliciosas empanadas argentinas.
        </p>
        <p>
          Los esperamos para disfrutar de un espacio tranquilo y una terraza
          donde hacer una pausa para relajarse.
        </p>
        <p>
          <strong>
            <em>Laura y Osvaldo</em>
          </strong>
        </p>
      </div>
    </Section>
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
