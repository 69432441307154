import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  FB_LINK,
  INSTAGRAM_LINK,
  WHATSAPP_LINK,
} from "../../constants/constants"
import ExternalLink from "../ExternalLink"
import Section from "../Section"
import "./Location.css"

// eslint-disable-next-line no-empty-pattern
export default function Location({}: IProps) {
  return (
    <Section title="Aqui los esperamos!" direction="up">
      <iframe
        className="map"
        title="Location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6060.979431764884!2d-3.9311612093366937!3d40.57494462771326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9184fe2ef7bba761!2sBLESSING%2C%20Coffee%2C%20Deli%20%26%20Empanadas!5e0!3m2!1sen!2sil!4v1657119252977!5m2!1sen!2sil"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <p className="address">
        C. Cam. de Valladolid, 14, 28250
        <br />
        Torrelodones, Madrid, Spain
      </p>
      <div className="footer-links">
        <ExternalLink href={INSTAGRAM_LINK}>
          <FontAwesomeIcon icon={faInstagram} /> Instagram
        </ExternalLink>
        <ExternalLink href={FB_LINK}>
          <FontAwesomeIcon icon={faFacebook} /> Facebook
        </ExternalLink>
        <ExternalLink href={WHATSAPP_LINK}>
          <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
        </ExternalLink>
        <ExternalLink href="tel:664605015">
          <FontAwesomeIcon icon={faPhone} /> 664 605 015
        </ExternalLink>
      </div>
    </Section>
  )
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
