import "./ExternalLink.css"

// eslint-disable-next-line no-empty-pattern
export default function ExternalLink({
  children,
  href,
  className = "",
  ...props
}: IProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      className={className}
      {...props}
    >
      {children}
    </a>
  )
}

interface IProps {
  children: React.ReactNode
  href: string
  className?: string
  style?: React.CSSProperties
}
