/* eslint-disable no-console */
import { isDev } from "../utils/env"

const logger = Object.freeze({
  log,
  info,
  warn,
  error,
})

export default logger

export { shouldSupressLog }

function log(prefix: string, ...msgs: any[]) {
  if (shouldSupressLog()) return

  console.log(...formatMsgs(prefix, msgs))
}

function info(prefix: string, ...msgs: any[]) {
  if (shouldSupressLog()) return

  console.info(...formatMsgs(prefix, msgs))
}

function warn(prefix: string, ...msgs: any[]) {
  if (shouldSupressLog()) return

  console.warn(...formatMsgs(prefix, msgs))
}

function error(prefix: string, ...msgs: any[]) {
  if (shouldSupressLog()) return

  console.error(...formatMsgs(prefix, msgs))
}

/*
 * Utils
 */
function shouldSupressLog() {
  return !isDev()
}

function formatMsgs(prefix: string, msgs: any[]) {
  return [`%c${prefix}`, "background: #222; color: #bada55", ...msgs]
}
