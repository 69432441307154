import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "@goldyjs/natives"

import "./styles/index.scss"
import App from "./App"
import initStore from "./store/init-store"
import init from "./init/init"

const store = initStore()

init(store)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  </React.StrictMode>,
)
