import "./Breadcrumbs.css"

// eslint-disable-next-line no-empty-pattern
export default function Breadcrumbs({ crumbs, onClick }: IProps) {
  return (
    <div>
      {crumbs.filter(Boolean).map((crumb, index) => (
        <a key={crumb} onClick={() => onClick(crumb, index)}>
          {index > 0 && " > "}
          {crumb}
        </a>
      ))}
    </div>
  )
}

interface IProps {
  crumbs: string[]
  onClick: (crumb: string, index: number) => void
}
