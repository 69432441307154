import { BrowserRouter, Routes as RouterRoutes, Route } from "react-router-dom"

import MainScreen from "./MainScreen"

export default function Routes() {
  return (
    <BrowserRouter>
      <RouterRoutes>
        <Route path="/">
          <Route path="" element={<MainScreen />} />
        </Route>
      </RouterRoutes>
    </BrowserRouter>
  )
}
