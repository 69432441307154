import createSagaMiddleware from "redux-saga"

import rootSaga from "../root.saga"

const sagaMiddleware = createSagaMiddleware()

export default sagaMiddleware

export function initSagaMiddleware() {
  sagaMiddleware.run(rootSaga)
}
