import React, { useRef } from "react"
import { useInViewport } from "react-in-viewport"

import "./InViewportAnimation.css"

export default function InViewportAnimation({ children, direction }: IProps) {
  const viewportRef = useRef<HTMLDivElement>(null)
  const { enterCount } = useInViewport(
    viewportRef,
    {},
    { disconnectOnLeave: true },
    // props,
  )

  const getTransform = () => {
    if (direction === "right") return "translateX(40px)"
    if (direction === "left") return "translateX(-40px)"
    if (direction === "up") return "translatey(40px)"
  }

  const getClassNames = () => {
    if (!direction) return

    return [
      "viewport-animation",
      enterCount > 0 ? "viewport-animation-trigger" : "",
    ].join(" ")
  }

  return (
    <div
      style={{ transform: getTransform() }}
      ref={viewportRef}
      className={getClassNames()}
    >
      {children}
    </div>
  )
}

interface IProps {
  children: React.ReactNode
  direction?: "left" | "right" | "up"
}
