import { combineReducers } from "@reduxjs/toolkit"

import menuReducer from "./menu/menu.slice"

const rootReducer = combineReducers({
  menu: menuReducer,
})

export default rootReducer

export type IRootState = ReturnType<typeof rootReducer>
