import { createSlice } from "@reduxjs/toolkit"

import { IRootState } from "../root-reducer"
import { IMenuData, IMenuCategoryMetadata } from "../../types/menu"

const initialState = {
  isReady: false,
  menuData: null as unknown as IMenuData,
  categoriesMetadata: null as unknown as IMenuCategoryMetadata[],
}

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuData: (state, action) => {
      state.menuData = action.payload
    },

    setCategoriesMetadata: (state, action) => {
      state.categoriesMetadata = action.payload
    },
  },
})

export const { setMenuData, setCategoriesMetadata } = menuSlice.actions

export const selectMenu = (state: IRootState) => state.menu

const menuReducer = menuSlice.reducer

export default menuReducer
