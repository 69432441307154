import img from "../../images/empanadas.jpeg"
import Section from "../Section"

import "./Empanadas.css"

// eslint-disable-next-line no-empty-pattern
export default function Empanadas({}: IProps) {
  return (
    <Section title="Empanadas" direction="up">
      <div className="empanadas">
        <img src={img} alt="empanadas" />
        <p>
          Las empanadas Graciana están hechas con productos frescos y naturales,
          manteniendo la tradición de las auténticas empanadas argentinas, desde
          la masa al relleno.
        </p>
        <p>
          Más de 20 sabores de empanadas, incluyendo opciones vegetarianas y
          veganas.
        </p>
        <p>
          En la <a href="#menu">Carta</a> pueden ver todas
        </p>
      </div>
    </Section>
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
